import './style';
import App from './components/app';
import {render} from 'preact';

window.FCMAP_SHOW = config => {
  let _container;

  try {
    _container = document.getElementById (config.containerId);
  } catch (e) {
    _container = document.getElementById ('kffc-smartmap__container');
  }

  render (<App config={config} />, _container);
};
